import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningIcon from "src/assets/svg-icons/warning.svg";

const CheckedOutTicket = () => {
  const { t } = useTranslation();

  return (
    <Box
      height={"70vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img src={WarningIcon} alt="" />
      <Typography
        fontSize={"24px"}
        fontWeight={600}
        mt={2}
        mb={0.5}
        textAlign={"center"}
      >
        {t("carCheckedOut")}
      </Typography>
    </Box>
  );
};

export default CheckedOutTicket;
