import { AxiosError } from "axios";

export function genericErrorHandler(error: AxiosError): any {
  const statusCode = error.response ? error.response.status : null;
  if (!statusCode) {
    alert("Opps! Connection error,Please check your network connection.");
  } else {
    if (statusCode === 401) {
      alert("Unauthenticated, Please Log In to continue");
    } else if (statusCode > 499) {
      alert("Opps! server error,We're working on it, Please try again later");
    }
    return error;
  }
}
