import axios, { AxiosError, AxiosResponse } from "axios";
import { genericErrorHandler } from "src/utils/_genericErrorHandler";

// Create an axios instance
const preBookingApiClient = axios.create({
  baseURL: process.env.REACT_APP_PRE_BOOKING_API_BASE,
});

preBookingApiClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    // Handle errors globally using the provided genericErrorHandler
    genericErrorHandler(error);
    return Promise.reject(error);
  }
);

export default preBookingApiClient;
