import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { FormFields } from "../../constants";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ILocation } from "src/types/pre-booking";
import { useInitiateBookingMutation } from "src/api/pre-booking";
import moment from "moment";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";

interface IProps {
  locationData: ILocation | undefined;
}

const BookingForm: React.FC<IProps> = ({ locationData }) => {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openDate, setOpenDate] = useState(false);
  const [openTime, setOpenTime] = useState(false);

  // APIS
  const { mutate, status, data, error, isPending } =
    useInitiateBookingMutation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useFormContext<FormFields>();

  const validateDateTimeInPast = (arrivingDate: Date, arrivingTime: Date) => {
    // Combine the date and time into a single moment object
    const arrivingDateTime = moment(arrivingDate).set({
      hour: arrivingTime.getHours(),
      minute: arrivingTime.getMinutes(),
      second: arrivingTime.getSeconds(),
      millisecond: arrivingTime.getMilliseconds(),
    });

    // Get the current date and time
    const now = moment();

    // Check if the arriving date and time is in the past
    return arrivingDateTime.isBefore(now);
  };

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    console.log({ data });
    if (validateDateTimeInPast(data.arrivingDate, data.arrivingTime)) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("canNotPickPrevTime"),
        },
      ]);
      return;
    }

    const arrivingDateTime = moment(data.arrivingDate).set({
      hour: data.arrivingTime.getHours(),
      minute: data.arrivingTime.getMinutes(),
      second: 0,
      millisecond: 0,
    });

    mutate({
      locationId: locationData?.locationId as string,
      name: data.name,
      isoCode: "SA",
      countryCode: "966",
      mobileNumber: data.mobileNumber,
      arrivingTime: arrivingDateTime.valueOf(),
    });
  };

  console.log({ locationData });

  const handleOpenDatePicker = () => {
    setOpenDate(true);
  };

  const handleCloseDatePicker = () => {
    setOpenDate(false);
  };

  const handleOpenTimePicker = () => {
    setOpenTime(true);
  };

  const handleCloseTimePicker = () => {
    setOpenTime(false);
  };

  useEffect(() => {
    if (status === "success") {
      window.location.href = data?.data.paymentUrl;
      return;
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box borderRadius={"12px"} overflow={"hidden"}>
        {/* header */}
        <Box
          bgcolor={"#383838CC"}
          padding={{ xs: "30px 16px", md: "30px 113px" }}
        >
          <Typography
            color={"primary"}
            fontSize={"24px"}
            fontWeight={500}
            mb={3}
          >
            {t("locationInfo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box
                display={"flex"}
                gap={3}
                alignItems={"center"}
                bgcolor={"#222"}
                p={2}
                borderRadius={"6px"}
              >
                <Box width={"1px"} height={"45px"} bgcolor={"#D4AD53"} />
                <Box>
                  <Typography fontSize={"14px"} color={"primary"} mb={"5px"}>
                    {t("locationName")}
                  </Typography>
                  <Typography>{locationData?.locationName}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display={"flex"}
                gap={3}
                alignItems={"center"}
                bgcolor={"#222"}
                p={2}
                borderRadius={"6px"}
              >
                <Box width={"1px"} height={"45px"} bgcolor={"#D4AD53"} />
                <Box>
                  <Typography fontSize={"14px"} color={"primary"} mb={"5px"}>
                    {t("valetServicePrice")}
                  </Typography>
                  <Stack direction={"row"} gap={2}>
                    <Typography>
                      {t("sar")} {locationData?.valetPrice}{" "}
                    </Typography>
                    {!!locationData?.priceTimeLimit && (
                      <Typography color={"#737373"}>
                        ({t("onlyForTheFirst")}{" "}
                        {locationData?.priceTimeLimit > 2
                          ? locationData?.priceTimeLimit
                          : ""}{" "}
                        {t(
                          locationData?.priceTimeLimit === 1
                            ? "hour"
                            : locationData?.priceTimeLimit === 2
                            ? "twoHours"
                            : "hours"
                        )}
                        )
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* content */}
        <Box
          bgcolor={"#272727"}
          padding={{ xs: "30px 16px", md: "30px 113px" }}
        >
          <Typography
            color={"primary"}
            fontSize={"24px"}
            fontWeight={500}
            mb={3}
          >
            {t("bookingInfo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mb: "10px" }}>{t("name")}</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={t("name")}
                    {...field}
                    error={!!errors.name}
                    helperText={t(`${errors.name?.message || ""}`)}
                    fullWidth
                    size="medium"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mb: "10px" }}>{t("mobileNumber")}</Typography>
              <div dir="ltr">
                <Controller
                  name="mobileNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder={t("mobileNumber")}
                      {...field}
                      error={!!errors.mobileNumber}
                      helperText={t(`${errors.mobileNumber?.message || ""}`)}
                      fullWidth
                      size="medium"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography
                              sx={{
                                direction:
                                  i18n.language === "en" ? "ltr" : "rtl",
                              }}
                            >
                              +966
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mb: "10px" }}>{t("arrivingDate")}</Typography>
              <div dir="ltr">
                <Controller
                  name="arrivingDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.arrivingDate,
                          helperText: t(
                            `${errors.arrivingDate?.message || ""}`
                          ),
                          onClick: handleOpenDatePicker,
                        },
                      }}
                      format={"dd/MM/yyyy"}
                      open={openDate}
                      onOpen={handleOpenDatePicker}
                      onClose={handleCloseDatePicker}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ direction: "ltr" }}>
              <Typography sx={{ mb: "10px" }}>{t("arrivingTime")}</Typography>
              <div dir="ltr">
                <Controller
                  name="arrivingTime"
                  control={control}
                  render={({ field }) => (
                    <DesktopTimePicker
                      {...field}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.arrivingTime,
                          helperText: t(
                            `${errors.arrivingTime?.message || ""}`
                          ),
                          onClick: handleOpenTimePicker,
                        },
                      }}
                      ampm={true}
                      format={i18n.language === "en" ? "hh:mm aa" : "hh:mm aa"}
                      open={openTime}
                      onOpen={handleOpenTimePicker}
                      onClose={handleCloseTimePicker}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>

          <Stack mt={"62px"}>
            <LoadingButton
              size="large"
              variant="contained"
              sx={{ mx: "auto", minWidth: "210px" }}
              type="submit"
              loading={isPending || !!data}
            >
              {t("bookNow")}
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </form>
  );
};

export default BookingForm;
