import { Box, Typography } from "@mui/material";

const StatusChip = ({
  label,
  variant = "primary",
}: {
  label: string;
  variant?: "primary" | "success" | "error";
}) => {
  return (
    <Box
      p={"2px 19px"}
      borderRadius={"20px"}
      bgcolor={
        variant === "success"
          ? "#34A85333"
          : variant === "error"
          ? "#EB001B33"
          : "#CC9C3333"
      }
      color={
        variant === "success"
          ? "#34A853"
          : variant === "error"
          ? "#EB001B"
          : "#CC9C33"
      }
      border={"1px solid"}
    >
      <Typography fontWeight={700}>{label}</Typography>
    </Box>
  );
};

export default StatusChip;
