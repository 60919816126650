import React, { useEffect, useState } from "react";
import { Box, Rating, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useAddFeedbackMutation } from "src/api/feedback";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import SubmittedFeedback from "./submitted";
import { TobBar } from "src/shared/components/top-bar";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#D4AD53",
    width: "60px",
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiRating-iconHover": {},
  "& .MuiRating-iconEmpty": {
    color: "#3C3C3C",
    width: "60px",
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {},
});

const FeedBackPage: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [arsannRate, setArsannRate] = useState<number | null>(null);
  const [arsannComment, setArsannComment] = useState("");
  const [employeeRate, setEmployeeRate] = useState<number | null>(null);
  const [employeeComment, setEmployeeComment] = useState("");
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { mutate, isPending, data, status, error } = useAddFeedbackMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log({ e });
    if (arsannRate === null) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("pleaseRateArsannExperience"),
        },
      ]);
      return;
    }
    if (employeeRate === null) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("pleaseRateTheEmployee"),
        },
      ]);
      return;
    }
    const dataToSend = {
      ticketId: id as string,
      arsannRate: arsannRate ?? 0,
      arsannComment: arsannComment,
      employeeRate: employeeRate ?? 0,
      employeeComment: employeeComment,
    };
    mutate(dataToSend);
  };

  const isBadRate =
    (arsannRate !== null && arsannRate <= 2) ||
    (employeeRate !== null && employeeRate <= 2);

  useEffect(() => {
    if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.response?.data?.message || "Failed to submit",
        },
      ]);
    }
  }, [status]);

  console.log({ error, data });
  console.log(error?.error);

  return (
    <Box display={"flex"} justifyContent={"center"} minHeight={"95vh"}>
      <Box
        boxShadow={{ xs: "unset", sm: "0px 0px 10px rgba(255, 255, 255, 0.2)" }}
        borderRadius={"50px"}
        minWidth={{ xs: "100%", sm: "375px" }}
        maxWidth={{ xs: "100%", sm: "375px" }}
        px={"33px"}
        pb={"48px"}
        display={"flex"}
        flexDirection={"column"}
      >
        <TobBar />
        {!data ? (
          <Box py={3}>
            <form onSubmit={handleSubmit}>
              {/* Arsann experience */}
              <Typography mb={2} fontSize={"18px"} fontWeight={600}>
                {t("pleaseRateArsannExperience")}
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1.2}
              >
                <StyledRating
                  name="simple-controlled"
                  value={arsannRate}
                  size="large"
                  onChange={(event, newValue) => {
                    setArsannRate(newValue);
                  }}
                  sx={{ fontSize: "47px" }}
                  icon={<StarRateRoundedIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                />
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  width={"100%"}
                  maxWidth={"300px"}
                >
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#B42222"}
                  >
                    {t("soBad")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#BB6323"}
                  >
                    {t("bad")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#D4AD53"}
                  >
                    {t("average")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#A3E500"}
                  >
                    {t("good")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#00B437"}
                  >
                    {t("excellent")}
                  </Typography>
                </Stack>
              </Box>
              <Typography color={"primary"} fontSize={"14px"} mt={2} mb={1}>
                {t("commentOptional")}
              </Typography>
              <TextField
                fullWidth
                multiline
                placeholder={t("describeYourExperienceHere")}
                rows={3}
                value={arsannComment}
                onChange={(e) => setArsannComment(e.target.value)}
                sx={{ background: "#1F1F20" }}
              />
              {/* employee */}
              <Typography mt={4} mb={2} fontSize={"18px"} fontWeight={600}>
                {t("pleaseRateTheEmployee")}
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1.2}
              >
                <StyledRating
                  name="simple-controlled"
                  value={employeeRate}
                  size="large"
                  onChange={(event, newValue) => {
                    setEmployeeRate(newValue);
                  }}
                  sx={{ fontSize: "47px" }}
                  icon={<StarRateRoundedIcon fontSize="inherit" />}
                  emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                />
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  width={"100%"}
                  maxWidth={"300px"}
                >
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#B42222"}
                  >
                    {t("soBad")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#BB6323"}
                  >
                    {t("bad")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#D4AD53"}
                  >
                    {t("average")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#A3E500"}
                  >
                    {t("good")}
                  </Typography>
                  <Typography
                    fontSize={"11px"}
                    width={"20%"}
                    align="center"
                    color={"#00B437"}
                  >
                    {t("excellent")}
                  </Typography>
                </Stack>
              </Box>

              <Typography color={"primary"} fontSize={"14px"} mt={2} mb={1}>
                {t("commentOptional")}
              </Typography>
              <TextField
                fullWidth
                multiline
                placeholder={t("describeYourExperienceHere")}
                rows={3}
                value={employeeComment}
                onChange={(e) => setEmployeeComment(e.target.value)}
                sx={{ mb: "30px", background: "#1F1F20" }}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={isPending}
              >
                {t("submit")}
              </LoadingButton>
            </form>
          </Box>
        ) : (
          <SubmittedFeedback isBadRate={isBadRate} />
        )}
      </Box>
    </Box>
  );
};

export default FeedBackPage;
