import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import LocationIcon from "src/assets/svg-icons/location.svg";
import { useTranslation } from "react-i18next";

interface IProps {}

const NoLocationFound: React.FC<IProps> = ({}) => {
  const { t } = useTranslation();

  return (
    <Box borderRadius={"12px"} overflow={"hidden"}>
      <Stack
        bgcolor={"#272727"}
        padding={"80px 113px 100px 113px"}
        alignItems={"center"}
      >
        <img src={LocationIcon} alt="" />
        <Typography
          mb={"60px"}
          color={"primary"}
          fontSize={"24px"}
          fontWeight={700}
          textTransform={"uppercase"}
          align="center"
        >
          {t("noLocationFound")}!
        </Typography>
      </Stack>
    </Box>
  );
};

export default NoLocationFound;
