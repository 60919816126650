import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import ApplePayIcon from "src/assets/svg-icons/black-apple-pay.svg";
import { useParams } from "react-router-dom";

const MERCHANT_ID = process.env.APPLE_PAY_MERCHANT_ID;
const BACKEND_URL_VALIDATE_SESSION =
  process.env.REACT_APP_API_BASE + "/verifyApplepaySession";

const ApplePayButton = ({ amount, mutatePay, setApplePaySession }) => {
  const [canPayByApplePay, setCanPayByApplePay] = useState(false);
  const params = useParams();

  let applePaySession;

  useEffect(() => {
    if (window.ApplePaySession) {
      setCanPayByApplePay(true);
      //TODO: next condition now has no value as setCanPayByApplePay is true
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(MERCHANT_ID);
      promise.then(function (canMakePayments) {
        if (canMakePayments) {
          console.log('app pay button component', {canMakePayments, MERCHANT_ID});
          setCanPayByApplePay(true);
        }
      });
    }
  }, []);

  // validate session API
  const validateTheSession = (theValidationUrl, callback) => {
    //we send the validation URL to the backend
    axios
      .post(BACKEND_URL_VALIDATE_SESSION, {
        appleUrl: theValidationUrl,
      })
      .then((response) => {
        console.log("Merchant validation response:" , {response});
        callback(response?.data);
      })
      .catch((error) => console.error("Error validating merchant:", error));
  };

  const handleClick = () => {
    applePaySession = new window.ApplePaySession(6, {
      countryCode: "SA",
      currencyCode: "SAR",
      merchantCapabilities: ["supports3DS"],
      supportedNetworks: ["visa", "masterCard", "amex", "discover", "mada"],
      total: {
        label: "",
        type: "final",
        amount: amount.toFixed(2),
      },
    });

    // Validate the session
    applePaySession.onvalidatemerchant = (event) => {
      console.log("validate step");
      const validationURL = event.validationURL;
      console.log({ validationURL });
      validateTheSession(validationURL, (merchantSession) => {
        console.log({ merchantSession });
        applePaySession.completeMerchantValidation(merchantSession);
      });
    };

    // Sending payment token
    applePaySession.onpaymentauthorized = (event) => {
      console.log("authorize step");
      const applePayToken = event.payment.token;
      console.log({ applePayToken });
      mutatePay({
        ticketId: params.id,
        type: "applepay",
        token_data: applePayToken,
      });
    };

    setApplePaySession(applePaySession);

    // Begin the apple pay session
    applePaySession.begin();
  };

  return (
    <>
      {canPayByApplePay && (
        <Button
          sx={{
            minWidth: "75%",
            background: "white",
            color: "gray",
            borderRadius: "4px",
            height: "38px",
          }}
          onClick={handleClick}
          variant="contained"
          color="tertiary"
        >
          <img height={17} src={ApplePayIcon} alt="" />
        </Button>
      )}
    </>
  );
};

export default ApplePayButton;
