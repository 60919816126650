import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SuccessIcon from "src/assets/svg-icons/success.svg";
import { ICarRequest } from "src/types/car-request";
import { TicketInfoBox } from "../ticket-info-box";

const RequestedSuccessfully = ({ data }: { data: ICarRequest }) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack alignItems={"center"} mb={0}>
        <img width={130} src={SuccessIcon} alt="" />
        <Typography mt={1} mb={2} fontSize={"24px"} fontWeight={600}>
          {t("requestedSuccessfully")}
        </Typography>
      </Stack>
      <TicketInfoBox data={data} />
      <Typography
        color={"textPrimary"}
        mt={3}
        fontSize={"12px"}
        fontWeight={300}
        textAlign={"center"}
      >
        {t("PressButtonToCancelReq")}
      </Typography>
    </>
  );
};

export default RequestedSuccessfully;
