import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ICarRequest } from "src/types/car-request";
import { TicketInfoBox } from "../ticket-info-box";

const TicketInfo = ({ data }: { data: ICarRequest }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box mt={"47px"}>
        <TicketInfoBox data={data} hiddenPaymentStatus={!data.paymentInfo} />
      </Box>
      <Typography
        color={"textPrimary"}
        mt={2}
        fontSize={"12px"}
        fontWeight={300}
        textAlign={"center"}
      >
        {t("arsannNotResponsible")}
      </Typography>
    </>
  );
};

export default TicketInfo;
