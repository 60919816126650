import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FailedIcon from "src/assets/svg-icons/failed.svg";

const FailedTicket = () => {
  const { t } = useTranslation();

  return (
    <Box
      height={"70vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img src={FailedIcon} alt="" />
      <Typography fontSize={"24px"} fontWeight={600} mt={2} mb={0.5}>
        {t("failedGetTicket")}
      </Typography>
      <Typography fontWeight={600}>{t("linkRequestNotValid")}</Typography>
    </Box>
  );
};

export default FailedTicket;
