import React, { useEffect, useState } from "react";
import { LocalStorageConstants } from "src/constants/_localStorage";
import i18n from "src/i18n";
import { Locale } from "src/types/generic";
import {
  getLocalStoredData,
  setLocalStoredData,
} from "src/utils/_localStorage";

export const LocaleContext = React.createContext<{
  locale: Locale;
  setLocale: Function;
}>({
  locale: "en",
  setLocale: () => {},
});

const LocaleContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [locale, setLocale] = useState<Locale>(
    getLocalStoredData(LocalStorageConstants.locale) || "ar"
  );
  // Update the local storage with the selected locale when it changes
  useEffect(() => {
    i18n.changeLanguage(locale);
    setLocalStoredData(LocalStorageConstants.locale, locale);
    document.dir = locale === "ar" ? "rtl" : "ltr";
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
