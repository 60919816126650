import { BrowserRouter, Route, Routes } from "react-router-dom";
import CarRequestPage from "src/pages/car-request";
import NotFound from "src/shared/components/not-found";
import PreBookingPage from "src/pages/pre-booking";
import FeedBackPage from "src/pages/feedback";

function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<CarRequestPage />} path="/request-car/:id" />
        <Route element={<PreBookingPage />} path="/pre-booking/:locationId" />
        <Route element={<FeedBackPage />} path="/feedback/:id" />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
