import React, { useEffect } from "react";
import Routes from "./routes";
import { useRecoilState } from "recoil";
import { notificationsState } from "./store/notifications";
import { Snackbar, Alert } from "@mui/material";
import { Notification } from "./store/notifications";
import LogRocket from 'logrocket';

function App() {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const handleClose = (index: number, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    let copyOfNotifications = [...notifications];
    copyOfNotifications.splice(index, 1);
    setNotifications(copyOfNotifications);
  };

  useEffect(() => {
    LogRocket.init('o1zahc/arsann');
  }, [])

  return (
    <>
      <Routes />
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification: Notification, index: number) => (
          <Snackbar
            key={index}
            open={true}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={(e, reason) => handleClose(index, reason)}
          >
            <Alert
              onClose={() => handleClose(index)}
              severity={notification.type}
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        ))}
    </>
  );
}

export default App;
