import {
  Stack,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApplePayIcon from "src/assets/svg-icons/apple-pay.svg";
import GooglePayIcon from "src/assets/GPay_Mark.png";
import VisaCreditIcon from "src/assets/svg-icons/visa-credit.svg";
import { FormData } from "../../constants";
import ApplePayButton from "src/shared/components/apple-pay-button";
import CustomGooglePayButton from "src/shared/components/google-pay-button";
import { useEffect, useState } from "react";

const PaymentType = ({
  price,
  mutatePay,
  setApplePaySession,
}: {
  price: number;
  mutatePay: Function;
  setApplePaySession: any;
}) => {
  const { t } = useTranslation();
  const [canPayWithApplePayment, setCanPayWithApplePayment] = useState(false);

  useEffect(() => {
    const merchantId = process.env.APPLE_PAY_MERCHANT_ID;
    console.log({merchantId});
    const windowCopy: any = window;
    console.log("browser supports apple pay:", !!windowCopy?.ApplePaySession);

    if (windowCopy?.ApplePaySession) {
      setCanPayWithApplePayment(true);
      //TODO: next condition now has no value
      const promise = windowCopy.ApplePaySession.canMakePaymentsWithActiveCard(
        process.env.APPLE_PAY_MERCHANT_ID
      );
      console.log({ promise });
      promise.then(function (canMakePayments: boolean) {
        console.log("Merchant can receive apple pay:", { canMakePayments });
        if (canMakePayments) {
          setCanPayWithApplePayment(true);
        }
      });
    }
  }, []);

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<FormData>();

  console.log({ canPayWithApplePayment });

  return (
    <Stack mt={4} alignItems={"center"}>
      <Box
        display={"inline-block"}
        bgcolor={"#333536"}
        color={"#BBBBBB"}
        padding={"4px 10px"}
        borderRadius={"6px"}
      >
        <Typography>{t("yourFeesIs")}</Typography>
      </Box>
      <Typography fontSize={"48px"} fontWeight={700} my={1}>
        {price} {t("sar")}
      </Typography>
      <FormControl fullWidth error={!!errors.paymentType}>
        <Controller
          name="paymentType"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              key={field.value}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              sx={{ gap: 2 }}
            >
              <Stack borderBottom={"1px solid #5F5C5C"} pb={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <FormControlLabel
                    value="card"
                    control={<Radio />}
                    label={t("creditDebitCard")}
                  />
                  <img src={VisaCreditIcon} alt="" />
                </Stack>

                {watch("paymentType") === "card" && (
                  <Stack gap={2}>
                    <Box p={"10px"} bgcolor={"#151515"} borderRadius={"12px"}>
                      <Typography fontSize={"9px"}>
                        {t("paySecurelyWithVisaOrMastercard")}
                      </Typography>
                    </Box>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={t("cardholderName")}
                          {...field}
                          error={!!errors.name}
                          helperText={t(`${errors.name?.message || ""}`)}
                          fullWidth
                          size="small"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      name="cardNumber"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={t("cardNumber")}
                          {...field}
                          error={!!errors.cardNumber}
                          helperText={t(`${errors.cardNumber?.message || ""}`)}
                          fullWidth
                          size="small"
                          variant="outlined"
                        />
                      )}
                    />
                    <Stack direction={"row"} gap={2}>
                      <Controller
                        name="expiry_at"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label={t("expiryDate")}
                            placeholder="MM/YY"
                            // {...field}
                            value={value}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue.length === 2 &&
                                Number(watch("expiry_at")?.length) < 2 && // to enable deleting
                                !inputValue.includes("/")
                              ) {
                                // Automatically add '/' after entering 2 digits (month)
                                onChange(inputValue + "/");
                              } else {
                                onChange(inputValue);
                              }
                            }}
                            error={!!errors.expiry_at}
                            helperText={t(`${errors.expiry_at?.message || ""}`)}
                            fullWidth
                            size="small"
                            variant="outlined"
                            inputProps={{
                              maxLength: 5, // Limit input to 5 characters (MM/YY format)
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="cvv"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            label={t("CVV")}
                            {...field}
                            error={!!errors.cvv}
                            helperText={t(`${errors.cvv?.message || ""}`)}
                            fullWidth
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                )}
              </Stack>
              {canPayWithApplePayment && (
                <Stack borderBottom={"1px solid #5F5C5C"} pb={2}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      value="applepay"
                      control={<Radio />}
                      label={t("applePay")}
                    />
                    <img src={ApplePayIcon} alt="" />
                  </Stack>

                  {watch("paymentType") === "applepay" && (
                    <Box display={"flex"} justifyContent={"center"} mt={0.5}>
                      <ApplePayButton
                        amount={price}
                        mutatePay={mutatePay}
                        setApplePaySession={setApplePaySession}
                      />
                    </Box>
                  )}
                </Stack>
              )}

              {/* <Stack borderBottom={"1px solid #5F5C5C"} pb={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <FormControlLabel
                    value="googlepay"
                    control={<Radio />}
                    label={t("googlePay")}
                  />
                  <img height={35} src={GooglePayIcon} alt="" />
                </Stack>

                {watch("paymentType") === "googlepay" && (
                  <Box display={"flex"} justifyContent={"center"} mt={1}>
                    <CustomGooglePayButton
                      amount={price}
                      mutatePay={mutatePay}
                    />
                  </Box>
                )}
              </Stack> */}
              <FormControlLabel
                value="cashier"
                control={<Radio />}
                label={t("atTheCashier")}
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    </Stack>
  );
};

export default PaymentType;
