import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningIcon from "src/assets/svg-icons/warning.svg";
import { ICarRequest } from "src/types/car-request";
import { TicketInfoBox } from "../ticket-info-box";

const CancelledSuccessfully = ({ data }: { data: ICarRequest }) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack alignItems={"center"}>
        <img width={130} src={WarningIcon} alt="" />
        <Typography
          mt={1}
          mb={0.5}
          fontSize={"23px"}
          fontWeight={600}
          textAlign={"center"}
        >
          {t("yourRequestCanceled")}
        </Typography>
        <Typography mb={2}>{t("readyToReRequest")}</Typography>
      </Stack>
      <TicketInfoBox data={data} />
    </>
  );
};

export default CancelledSuccessfully;
