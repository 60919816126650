import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UpsetIcon from "src/assets/svg-icons/upset.svg";
import DoneV2Icon from "src/assets/svg-icons/done-v2.svg";

const SubmittedFeedback = ({ isBadRate }: { isBadRate: boolean }) => {
  const { t } = useTranslation();

  return (
    <Box
      height={"70vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img src={isBadRate ? UpsetIcon : DoneV2Icon} alt="" />
      <Typography
        fontSize={"24px"}
        fontWeight={700}
        mt={0}
        mb={1}
        textAlign={"center"}
        color={"primary"}
        textTransform={"uppercase"}
      >
        {t(isBadRate ? "sorry" : "thankYou")}
      </Typography>
      <Typography
        fontSize={"14px"}
        fontWeight={600}
        mb={0.5}
        textAlign={"center"}
        maxWidth={"253px"}
      >
        {t(isBadRate ? "WeAreSorryToHearThat" : "yourFeedbackHasBeenSubmitted")}
      </Typography>
    </Box>
  );
};

export default SubmittedFeedback;
