import * as Yup from "yup";

export const defaultSteps = [
  {
    id: "info",
    fields: [],
  },
  {
    id: "timeAndGate",
    fields: ["time", "gate"],
  },
  {
    id: "requested",
    fields: [],
  },
  {
    id: "cancelled",
    fields: [],
  },
];

export interface FormData extends Yup.InferType<typeof schema> {}

// validations
export const schema = Yup.object().shape({
  // Time and gate step
  time: Yup.string().required("Required"),
  gate: Yup.string().required("Required"),
  // Payment type
  paymentType: Yup.string()
    .required("Required")
    .oneOf(["card", "applepay", "googlepay", "cashier"] as const),
  // card
  name: Yup.string().when("paymentType", {
    is: "card",
    then: (value) => value.required("Required"),
  }),
  cardNumber: Yup.string().when("paymentType", {
    is: "card",
    then: (value) =>
      value
        .required("Required")
        .matches(/^\d{16}$/, "invalidCardNumberShouldBe16")
        .test("luhn", "invalidCardNumber", isValidLuhn),
  }),
  expiry_at: Yup.string().when("paymentType", {
    is: "card",
    then: (value) =>
      value
        .required("Required")
        .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "invalidFormatUseMM/YY"),
  }),
  cvv: Yup.string().when("paymentType", {
    is: "card",
    then: (value) =>
      value.required("Required").matches(/^\d{3,4}$/, "invalidCVV"),
  }),
});

function isValidLuhn(value: string): boolean {
  // Implementation of the Luhn algorithm
  let sum = 0;
  let isEven = false;

  for (let i = value.length - 1; i >= 0; i--) {
    let digit = parseInt(value[i], 10);

    if (isEven) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    isEven = !isEven;
  }

  return sum % 10 === 0;
}
