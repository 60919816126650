import React, { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import createCustomTheme, { cacheRtl } from "./theme";
import App from "./App";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalStorageConstants } from "./constants/_localStorage";
import { getLocalStoredData } from "./utils/_localStorage";
import LocaleContextProvider from "./contexts/locale.tsx";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CssBaseline } from "@mui/material";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Root: React.FC = () => {
  const { i18n } = useTranslation();
  const [theme, setTheme] = useState(createCustomTheme());
  const storedLanguage = getLocalStoredData(LocalStorageConstants.locale);

  useLayoutEffect(() => {
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      const direction = storedLanguage === "ar" ? "rtl" : "ltr";
      setTheme(createCustomTheme(direction));
      document.dir = direction;
    }
  }, [i18n.language]);

  const stylingCache =
    storedLanguage === "ar" ? cacheRtl : createCache({ key: "muiltr" });

  return (
    <CacheProvider value={stylingCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CacheProvider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <LocaleContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Root />
            </LocalizationProvider>
          </LocaleContextProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
